<template>
  <div v-if="tabSelected" class="justify-content-center flex flex-wrap">
    <div v-if="setNames && chooseOption" class="w-full text-center">
      <div class="image-container">
        <img v-if="selectedOption !== ''" class="cropped-image" :src="getImageUrl(groupName, selectedOption, images)" />
        <div v-if="selectedOption === ''" class="placeholderz">Select an option</div>
      </div>
    </div>

    <CardItem v-if="(!setNames || !chooseOption) && item && item.length === 1" :itemData="item[0]" class="md:w-4"
      :image="`https://gfmemories.com/images/itemicon/${item[0].ui_id_1?.toUpperCase()}.png`"
      :title="cleanHTML(item[0].item_name[lang])" :description="quantity > 1 ? `x ${quantity}` : ''" />
    <CardItem v-if="(!setNames || !chooseOption) && chooseOption && item && item.length > 1" class="md:w-4"
      :itemData="scaleItemdata" :image="scaleImagen" :title="scaleTitle" :description="quantity > 1 ? `x ${quantity}` : ''">
    </CardItem>

    <div v-if="!setNames && (chooseOption && item && item.length > 1)" class="w-full">
      <select :on-change="updateImage()" v-model="selectedOption" style="width: 400px;"
        class="form-select form-select-lg container flex justify-content-center mt-5 mb-3">
        <option value="">Choose one</option>
        <option v-for="(opt, index) in orderedItems" :key="opt.item_id" :value="index">
          {{ opt.item_name[lang === 'br' ? 'pt' : lang] || 'N/A' }} {{ quantity > 1 ? `x ${quantity}` : '' }}
        </option>
      </select>
    </div>

    <div v-if="setNames && chooseOption" class="w-full">
      <select :on-change="updateImage()" v-model="selectedOption" style="width: 400px;"
        class="form-select form-select-lg container flex justify-content-center mt-5 mb-3">
        <option value="">Choose one</option>
        <option v-for="(opt, index) in setNames" :key="opt[lang]" :value="index">
          {{ opt[lang] || 'N/A' }} {{ quantity > 1 ? `x ${quantity}` : '' }}
        </option>
      </select>
    </div>
    <div class="flex justify-content-center w-full">
      <ButtonCustomGF v-if="(!scaleVal || (scaleVal && !claimed)) && verifiedAccount"
        class="flex justify-content-center mt-5 mb-3 w-full md:w-3"
        :class="(scaleVal || (scaleVal && !claimed)) && (chooseOption ? selectedOption !== '' : true)  && !isLoading? '' : 'disabled'" @click="claimScale" :msg="$t('claimAward')" />

      <ButtonCustomGF v-if="scaleVal && claimed && verifiedAccount"
        class="flex justify-content-center mt-5 mb-3 w-full md:w-3 disabled" :msg="$t('claimed')" />
    </div>

    <modalMessage v-if="showModal" :title="$t('messageInfo')" :status="status" :redirect="urlRedirect"
      :content="$t(modalContent)" @close="showModal = false" />
  </div>
</template>

<script>
import { ref } from 'vue'
import modalMessage from '@/components/modal/modalMessage'
import CardItem from '@/components/cards/card-item/cardItem'
import ButtonCustomGF from '@/components/button-custom/ButtonCustom'
import { useLoading } from 'vue-loading-overlay'
import service from '@/mixins/service.js'

export default {
  name: 'NewEscala',
  components: {
    CardItem,
    ButtonCustomGF,
    modalMessage
  },
  mixins: [service],
  props: {
    item: Array,
    ids: Array,
    scaleKey: String,
    scaleVal: Boolean,
    claimed: Boolean,
    groupName: String,
    images: Array,
    chooseOption: Boolean,
    setNames: Array,
    verifiedAccount: Boolean,
    tabSelected: String,
    quantity: Number,
  },
  methods: {
    getImageUrl(groupName, selectedOption, images) {
      try {
        const imageName = images[selectedOption];
        return `/images/${groupName}/${imageName}`;
      } catch (e) {
        console.error('No se encontró la imagen:', e);
        return '';
      }
    },
  },
  setup(props) {
    const modalContent = ref('')
    const urlRedirect = ref('')
    const status = ref('')
    const showModal = ref(false)
    let selectedOption = ref('')
    let scaleImagen = ref()
    let scaleTitle = ref()
    let scaleItemdata = ref();
    let lang = ref('')
    let isLoading = ref (false);
    let orderedItems = ref([])
    lang.value = localStorage.getItem('language');
    if (lang.value === 'br') {
      lang.value = 'pt';
    }
    const cleanHTML = (input) => {
      return input?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''
    }

    orderedItems.value = props.ids.map(id => props.item.find(it => it.item_id === id));

    const updateImage = async () => {
      if (props.images) return

      const itemVal = orderedItems.value[selectedOption.value]
      if (selectedOption.value === '') {
        scaleImagen.value = null;
        scaleTitle.value = 'Pick one';
        scaleItemdata.value = null;
      } else {
        scaleImagen.value = `https://gfmemories.com/images/itemicon/${itemVal.ui_id_1?.toUpperCase()}.png`;
        scaleTitle.value = itemVal.item_name[lang.value === 'br' ? 'pt' : lang.value]
        scaleItemdata.value = itemVal;
      }
    }

    const claimScale = async () => {
      const $loading = useLoading()
      const loader = $loading.show()
      isLoading.value = true;
      try {
        const data = {
          path: '/auth/claimScale',
          data: { scale: props.scaleKey },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }

        if (props.chooseOption && selectedOption.value !== '') {
          data.data.selectedOption = selectedOption.value;
        }

        const resp = await service.methods.callService(data)
        loader.hide()

        if (resp && resp.statusCode === 200) {
          modalContent.value = 'successSendItem'
          showModal.value = true
          urlRedirect.value = `scales`
          status.value = 'success'
        }
         else if(resp.statusCode === 429){
          modalContent.value = 'tooManyRequests'
          showModal.value = true
          status.value = 'failed'
          isLoading.value = false;
         }else {
          modalContent.value = 'errorOccurred'
          showModal.value = true
          status.value = 'failed'
          isLoading.value = false;
        }
      } catch (error) {
        loader.hide()
        modalContent.value = 'errorOccurred'
        showModal.value = true
        status.value = 'failed'
        isLoading.value = true;
      }
    }

    return {
      isLoading,
      claimScale,
      cleanHTML,
      orderedItems,
      modalContent,
      urlRedirect,
      status,
      showModal,
      selectedOption,
      scaleImagen,
      updateImage,
      lang,
      scaleTitle,
      scaleItemdata,
    }
  }
}
</script>

<style scoped>
.image-container {
  width: 200px;
  height: 300px;
  text-align: center;
  display: inline-block;
}

.cropped-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholderz {
  width: 200px;
  height: 300px;
  opacity: 0.5;
  background-color: dimgray;
  color: whitesmoke;
  cursor: default;
  display: inline-block;
  display: flex;
  align-items: center;
  /* vertically align text */
  justify-content: center;
  /* horizontally align text */
}
</style>
