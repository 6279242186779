<template>
  <div class="card-update shadow-lg card-section flex justify-content-evenly" :style="style">
    <div v-if="image" class="w-2 align-items-center flex justify-content-center tooltip1">
      <img :src="image" alt="" class="img-update">
        <TooltipItemData
          v-if="itemData"
          fromScale
          :key="JSON.stringify(itemData)"
          class="tooltip1-top tooltip2 exclusive"
          style="border: 1px solid white;" :itemData="itemData">
      </TooltipItemData>
    </div>
    <div class="card-container w-9 justify-content-center" :class="{ block: image? false: true }">
      <span class="card-update-title">{{ title }}</span>
      <p>
        <span class="card-description"> {{ description }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import imgCard from '@/assets/images/1x/card-update.png'
import TooltipItemData from '@/components/tooltip/TooltipItem.vue'
export default {
  name: 'card-item-main',
  components: { TooltipItemData },
  props: {
    image: String,
    msg: String,
    title: String,
    description: String,
    itemData: Object
  },
  setup() {
    let style = ref('null')
    let cardImg = ref(imgCard)
    //style = `background : linear-gradient(90deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`
    style = `background : linear-gradient(to right, #212121, #141414);boder: 3px solid #212121`
    return {
      style,
      cardImg,
    };
  },
};
</script>
<style>
@import './styles.css';
.tooltip1 {
  color: blue;
  text-decoration: none;
  position: relative;
  height: 100%;
}

.card-update{
  margin-top: 3em;
}

.tooltip1 .tooltip2 {
  background: black;
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
}

.tooltip1:hover .tooltip2 {
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
}

.tooltip1:hover .tooltip1-top {
  bottom: 130%;
  z-index: 99999;
}
</style>