<template>
  <BannerTitle :msg="$t('store')"></BannerTitle>
  <!-- <img class="img-bg" :src="bgImagebod" />-->
  <div class="cards-updates-double row flex justify-content-center m-0 mt-5">
    <div class="row flex justify-content-center m-0 w-9 flex-wrap">
      <div class="content_checkbox justify-content-center" style="display: flex; align-items: center;">
        <div class="checkbox-wrapper-12">
          <div class="cbx">
            <input v-model="terms_box" id="cbx-12" type="checkbox" />
            <label for="cbx-12"></label>
            <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
              <path d="M2 8.36364L6.23077 12L13 2"></path>
            </svg>
          </div>
          <!-- Gooey-->
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="goo-12">
                <!-- <fegaussianblur in="SourceGraphic" stddeviation="4" result="blur"></fegaussianblur>
              <fecolormatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo-12"></fecolormatrix>
              <feblend in="SourceGraphic" in2="goo-12"></feblend> -->
              </filter>
            </defs>
          </svg>

        </div>
        <span class="text_checkbox ml-2 text-white">{{ $t('checkedTermsBuy') }}</span>
      </div>
      <div v-if="!verifiedAccount && initialVerify" class="flex w-full justify-content-center">
        <div class="m-1 alert alert-warning  col-10 md:col-7 text-center" role="alert">
          <span class="fa fa-close"></span> {{ $t('accountNotVerified') }}
          <router-link to="/profile" aria-current="page" href="/">{{ $t('profile')
            }}</router-link>
        </div>
      </div>
      <div class="mt-4 flex justify-content-center">
    <div class="d-flex justify-content-center flex-wrap">
      <div v-if="listAvailableScales.includes(1)" class="mx-2">
        <input type="radio" class="btn-check" name="options-outlined" id="success-outlined" autocomplete="off">
        <label class="btn awardsOption border-transparent border-round-left md:w-20rem"
          :class="{ 'active': scaleType == 1 || scaleType == 0 }" for="success-outlined" @click="changeScaleType(1)">{{
            $t('MpBuyingLabelStore') }}</label>
      </div>
      <div v-if="listAvailableScales.includes(2)" class="mx-2">
        <input type="radio" class="btn-check" name="options-outlined" id="danger-outlined" autocomplete="off">
        <label class="btn awardsOption border-transparent md:w-20rem"
          :class="{ 'active': scaleType == 2, 'border-round-right': !listAvailableScales.includes(3) }"
          for="danger-outlined" @click="changeScaleType(2)">{{ $t('servicesLabelStore') }}
          <!-- <span class="fa fa-clock-o"></span> -->
        </label>
      </div>
    </div>
  </div>

    <CardPlanBuy v-if="scaleType==1" class="tab1Pay" :plan="prices.plans[0].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[0].bonus"
        :price="prices.plans[0].price" :mpPoints="prices.plans[0].mpPoints" :image="imgPay1" />
      <CardPlanBuy v-if="scaleType==1" class="tab1Pay" :plan="prices.plans[1].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[1].bonus"
        :price="prices.plans[1].price" :mpPoints="prices.plans[1].mpPoints" :image="imgPay2" />
      <CardPlanBuy v-if="scaleType==1" class="tab1Pay" :plan="prices.plans[2].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[2].bonus"
        :price="prices.plans[2].price" :mpPoints="prices.plans[2].mpPoints" :image="imgPay3" />
      <CardPlanBuy v-if="scaleType==1" class="tab1Pay" :plan="prices.plans[3].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[3].bonus"
        :price="prices.plans[3].price" :mpPoints="prices.plans[3].mpPoints" :image="imgPay4" />
      <CardPlanBuy v-if="scaleType==1" class="tab1Pay" :plan="prices.plans[4].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[4].bonus"
        :price="prices.plans[4].price" :mpPoints="prices.plans[4].mpPoints" :image="imgPay5" />
      <CardPlanBuy v-if="scaleType==1" class="tab1Pay" :plan="prices.plans[5].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[5].bonus"
        :price="prices.plans[5].price" :mpPoints="prices.plans[5].mpPoints" :image="imgPay6" />
        <CardPlanBuy v-if="scaleType==1" class="tab1Pay" :plan="prices.plans[14].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[14].bonus"
        :price="prices.plans[14].price" :mpPoints="prices.plans[14].mpPoints" :image="imgPay7" />


    <CardPlanBuyServices  v-if="scaleType==2" class="tab1Pay" :plan="prices.plans[6].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" 
        :price="prices.plans[6].price" :service_name="prices.plans[6].service_name" :image="service1" />
      <CardPlanBuyServices v-if="scaleType==2" class="tab1Pay" :plan="prices.plans[7].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[7].bonus"
        :price="prices.plans[7].price" :service_name="prices.plans[7].service_name" :image="service2" />
      <CardPlanBuyServices v-if="scaleType==2" class="tab1Pay" :plan="prices.plans[8].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[8].bonus"
        :price="prices.plans[8].price" :service_name="prices.plans[8].service_name" :image="service3" />
      <CardPlanBuyServices v-if="scaleType==2" class="tab1Pay" :plan="prices.plans[9].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[9].bonus"
        :price="prices.plans[9].price" :service_name="prices.plans[9].service_name" :image="service4" />
      <CardPlanBuyServices v-if="scaleType==2" class="tab1Pay" :plan="prices.plans[10].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[10].bonus"
        :price="prices.plans[10].price" :service_name="prices.plans[10].service_name" :image="service5" />
      <CardPlanBuyServices v-if="scaleType==2" class="tab1Pay" :plan="prices.plans[11].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[11].bonus"
        :price="prices.plans[11].price" :service_name="prices.plans[11].service_name" :image="service6" />
        <CardPlanBuyServices v-if="scaleType==2" class="tab1Pay" :plan="prices.plans[12].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[12].bonus"
        :price="prices.plans[12].price" :service_name="prices.plans[12].service_name" :image="service7" />
      <CardPlanBuyServices v-if="scaleType==2" class="tab1Pay" :plan="prices.plans[13].name"
        :checkValid="terms_box && (verifiedAccount || dateAccountOld)" :bonus="prices.plans[13].bonus"
        :price="prices.plans[13].price" :service_name="prices.plans[13].service_name" :image="service8" />

     
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import BannerTitle from '@/components/banner-title/BannerTitle'
import CardPlanBuy from '@/components/forms/store/CardPlanBuy'
import CardPlanBuyServices from '@/components/forms/store/CardPlanBuyServices'
import { ref } from "vue";
import plan1 from '@/assets/images/1x/1.png'
import plan2 from '@/assets/images/1x/2.png'
import plan3 from '@/assets/images/1x/3.png'
import plan4 from '@/assets/images/1x/4.png'
import plan5 from '@/assets/images/1x/5.png'
import plan6 from '@/assets/images/1x/6.png'
import plan7 from '@/assets/images/1x/7.png'
import service1 from '@/assets/images/1x/services/1.png'
import service2 from '@/assets/images/1x/services/2.png'
import service3 from '@/assets/images/1x/services/3.png'
import service4 from '@/assets/images/1x/services/4.png'
import service5 from '@/assets/images/1x/services/5.png'
import service6 from '@/assets/images/1x/services/6.png'
import service7 from '@/assets/images/1x/services/7.png'
import service8 from '@/assets/images/1x/services/8.png'
import service from '@/mixins/service';
import { useRoute, useRouter } from 'vue-router';
export default {
  name: 'PlansPayMPView',
  mixins: [service],
  components: {
    CardPlanBuy,
    BannerTitle,
    CardPlanBuyServices
  },
  setup() {
    const listAvailableScales = [1, 2];
    const verifiedAccount = ref(false);
    const initialVerify = ref(false);
    const dateAccountOld = ref(false);
    let scaleType;
    const route = useRoute().query;
    const router = useRouter();
    if (route.scale) {
      try {
        const routS = parseInt(route.scale);
        if (listAvailableScales.includes(routS)) {
          scaleType = ref(parseInt(route.scale));
        } else {
          router.push({ path: router.currentRoute.value.path, query: { scale: 1 } });
          scaleType = ref(1);
        }
      } catch (e) {
        //console.log('Invalid query')
        scaleType = ref(1);
      }
     } else {
      scaleType = ref(1);
    }
    let tabSelected = ref('');
    const accountVerified = async () => {
      const verifiedJS = JSON.parse(localStorage.getItem('vfa') || "false");
      if (!verifiedJS) {
        const data = {
          path: '/auth/getVerifyParameter',
          method: 'GET',
        }
        const resp = await service.methods.callService(data)
        if (resp.statusCode == 200) {
          verifiedAccount.value = resp.isVerify;
          dateAccountOld.value = new Date(resp.created_at) < new Date('2024-09-22T05:00:00');
        }
        initialVerify.value = true;
      } else {
        verifiedAccount.value = verifiedJS;
        initialVerify.value = true;
      }
    }
    const selectedTab = (tab) => {
      tabSelected.value = tab;
      document.getElementById('tabs')
    }
    const changeScaleType = (scaleSelected) => {
      scaleType.value = scaleSelected;
      tabSelected.value = null;
      const newQuery = { ...router.currentRoute.value.query }; // Clone existing query
      newQuery['scale'] = scaleSelected;
      if (router.currentRoute.value.path) {
        router.push({ path: router.currentRoute.value.path, query: newQuery });
      }
    }
    accountVerified();
    let prices = ref({
      plans: [
        {
          name: 'plan1',
          mpPoints: 5000,
          bonus: 0,
          price: 10
        },
        {
          name: 'plan2',
          mpPoints: 10000,
          bonus: 0,
          price: 20
        },
        {
          name: 'plan3',
          mpPoints: 15000,
          bonus: 0,
          price: 30
        },
        {
          name: 'plan4',
          mpPoints: 20000,
          bonus: 0,
          price: 40
        },
        {
          name: 'plan5',
          mpPoints: 25000,
          bonus: 0,
          price: 50
        },
        {
          name: 'plan6',
          mpPoints: 30000,
          bonus: 0,
          price: 60
        },
        {
          name: 'sCPN',
          service_name: 'changeNameT',
          bonus: 0,
          price: 5
        },
        {
          name: 'sRO',
          service_name: 'objectRecoveryT',
          bonus: 0,
          price: 5
        },
        {
          name: 'sCGN',
          service_name: 'changeNameGuildT',
          bonus: 0,
          price: 10
        },
        {
          name: 'sRPC',
          service_name: 'recoveryCharaterT',
          bonus: 0,
          price: 10
        },
        {
          name: 'sROTB',
          service_name: 'recoveryTbObjectT',
          bonus: 0,
          price: 15
        },
        {
          name: 'sROGVG',
          service_name: 'recoveryGVGObjectT',
          bonus: 0,
          price: 15
        },
        {
          name: 'sROMisc',
          service_name: 'recoveryMiscObjectT',
          bonus: 0,
          price: 15
        },
        {
          name: 'sRGenky',
          service_name: 'recoveryAlchemizedT',
          bonus: 0,
          price: 15
        },
        {
          name: 'plan7',
          mpPoints: 50000,
          bonus: 0,
          price: 100
        },
      ]
    })
    let imgPay1 = ref(plan1)
    let imgPay2 = ref(plan2)
    let imgPay3 = ref(plan3)
    let imgPay4 = ref(plan4)
    let imgPay5 = ref(plan5)
    let imgPay6 = ref(plan6)
    let imgPay7 = ref(plan7)
    let terms_box = ref(false)
    prices.value.plans.forEach(element => {
      element.bonus = element.mpPoints * 0.30
    })
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }
    topPage()
    return {
      imgPay1,
      imgPay2,
      imgPay3,
      imgPay4,
      imgPay5,
      imgPay7,
      service1,
      service2,
      service3,
      service4,
      service5,
      service6,
      service7,
      service8,
      scaleType,
      selectedTab,
      imgPay6,
      listAvailableScales,
      prices,
      terms_box,
      tabSelected,
      changeScaleType,
      verifiedAccount,
      initialVerify,
      dateAccountOld
    }
  },
}
</script>
<style scoped>
.content_checkbox {
  display: flex;

}

@media (min-width: 560px) {
  .content_checkbox {
    margin-left: 10%;
  }

  .text_checkbox {
    font-size: 14px;
  }
}
.tab1 {
  transition: transform .2s;
  /* Animation */
}

.tab1:hover {
  transform: scale(1.3);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.selectedTab {
  transform: scale(1.15);
}
.checkbox-wrapper-12 {
  vertical-align: middle;
  position: relative;
}

.checkbox-wrapper-12>svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.checkbox-wrapper-12 * {
  box-sizing: border-box;
}

.checkbox-wrapper-12 input[type="checkbox"] {
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.checkbox-wrapper-12 input[type="checkbox"]:focus {
  outline: 0;
}

.checkbox-wrapper-12 .cbx {
  width: 24px;
  height: 24px;
  top: calc(50vh - 12px);
  left: calc(50vw - 12px);
}

.checkbox-wrapper-12 .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #bfbfc0;
  border-radius: 50%;
}

.checkbox-wrapper-12 .cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-filter: url("#goo-12");
  filter: url("#goo-12");
  transform: trasnlate3d(0, 0, 0);
  pointer-events: none;
}
.awardsOption {
  border-radius: 0px;
  --bs-btn-color: #ffff;
  --bs-btn-bg: #212121;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e9952e;
  --bs-btn-active-border-color: transparent;
  --bs-btn-border-color: transparent;
  font-weight: 500;
}
.checkbox-wrapper-12 .cbx svg {
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 1;
  pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg path {
  stroke: #fff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  transition: stroke-dashoffset 0.3s ease;
  transition-delay: 0.2s;
}

.checkbox-wrapper-12 .cbx input:checked+label {
  animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper-12 .cbx input:checked+label+svg path {
  stroke-dashoffset: 0;
}

@-moz-keyframes splash-12 {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
  }

  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-webkit-keyframes splash-12 {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
  }

  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-o-keyframes splash-12 {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
  }

  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: #866efb;
    box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
  }

  100% {
    background: #866efb;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

.img-bg {
  padding-left: 15%;
  width: 85%;
  height: 80%;
}

.tab1Pay {
  transition: transform .2s;
  /* Animation */
}

.tab1Pay:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.cards-updates-double {
  margin-left: 8%;
  margin-top: 3%;
}
</style>
