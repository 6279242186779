<template>
  <BannerTitle :msg="$t('tierListRank')"></BannerTitle>
  <div class="container" style="color:white">
    <div style="height: 100%;">
      <div class="row main-row">
        <div class="col-sm-12 col-md-3"><select v-on:change="filterlevelrank" v-model="selectedOptionNivel"
            class="form-select form-select-sm mb-3">
            <option value="">{{ $t('textSelectboxLevel') }}</option>
            <option value="40">40</option>
            <option value="80">80</option>
            <option value="90">90</option>
            <option value="100">100</option>
          </select></div>
        <div class="col-sm-12 col-md-3"><select v-on:change="getTierRankedListClass" v-model="selectedOption"
            class="form-select form-select-sm mb-3 ">
            <option value="">{{ $t('textSelectboxClass') }}</option>
            <option value="3" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titleBerserker')
            }}
            </option>
            <option value="4" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titlePaladin') }}
            </option>
            <option value="7" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titleRanger') }}
            </option>
            <option value="8" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titleAsesino') }}
            </option>
            <option value="11" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titleClerigo')
            }}
            </option>
            <option value="12" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titleSabio') }}
            </option>
            <option value="15" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titleMagoBrujo')
            }}
            </option>
            <option value="16" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{
              $t('titleNigromante') }}
            </option>
            <option value="27" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{
              $t('titleArtificiero') }}
            </option>
            <option value="28" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titleArtillero')
            }}
            </option>
            <option value="54" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titleDuelista')
            }}
            </option>
            <option value="55" v-if="selectedOptionNivel === '40' || selectedOptionNivel === ''">{{ $t('titleRelojero')
            }}
            </option>
            <option value="17" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleDW') }}
            </option>
            <option value="18" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleHk') }}
            </option>
            <option value="19" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleTh') }}
            </option>
            <option value="20" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleAo') }}
            </option>
            <option value="21" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleProfeta')
            }}
            </option>
            <option value="22" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleMistico')
            }}
            </option>
            <option value="23" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleAM') }}
            </option>
            <option value="24" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleDM') }}
            </option>
            <option value="29" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titlePrime') }}
            </option>
            <option value="30" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleOptimus')
            }}
            </option>
            <option value="56" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">{{ $t('titleSamurai')
            }}
            </option>
            <option value="57" v-if="selectedOptionNivel === '80' || selectedOptionNivel === ''">
              {{ $t('titleMaestroTiempo') }}</option>
            <option value="32" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{ $t('titleDK') }}
            </option>
            <option value="33" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">
              {{ $t('titleCaballeroSagrado') }}</option>
            <option value="34" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{
              $t('titleBallestero') }}
            </option>
            <option value="35" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{ $t('titleNinja') }}
            </option>
            <option value="36" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{ $t('titleSanto') }}
            </option>
            <option value="37" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{ $t('titleChaman') }}
            </option>
            <option value="38" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{ $t('titleAvatar') }}
            </option>
            <option value="39" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{ $t('titleAmoAlmas')
            }}
            </option>
            <option value="40" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleDestructor') }}</option>
            <option value="41" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleCruzado') }}</option>
            <option value="42" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleDepredador') }}</option>
            <option value="43" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleShinobi') }}</option>
            <option value="44" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleArcangel') }}</option>
            <option value="45" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleDruida') }}</option>
            <option value="46" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleBrujo') }}</option>
            <option value="47" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleShinigami') }}</option>
            <option value="48" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{ $t('titleMegatron')
            }}
            </option>
            <option value="49" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{ $t('titleGalvatron')
            }}
            </option>
            <option value="50" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleOmega') }}</option>
            <option value="51" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleDomadorCelestial') }}</option>
            <option value="58" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">
              {{ $t('titleDimensionalista') }}</option>
            <option value="59" v-if="selectedOptionNivel === '90' || selectedOptionNivel === ''">{{ $t('titleOraculo')
            }}
            </option>
            <option value="60" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleEspectroMortal') }}</option>
            <option value="61" v-if="selectedOptionNivel === '100' || selectedOptionNivel === ''">
              {{ $t('titleAlteradorCosmico') }}</option>

          </select></div>
        <div class="col-sm-10 col-md-5" style="font-size: small;"><input class="form-control form-control-sm mb-3"
            v-model="textSearch" v-on:keyup="getTierListbyName()" type="text" :placeholder="$t('placeHolderSearch')"
            aria-label="Search">
        </div>
        <div class="col-sm-2 col-md-1 refresh-button d-flex justify-content-center"><img
            v-on:click="getTierRankedList()" alt="refresh list" :src="require(`@/assets/images/elo-rank/refresh.png`)"
            style="height: 35px;width: 35px;" /></div>
      </div>
      <div class="overflow-auto" style="max-height: 400px;overflow-x: hidden;">
        <table class="table table-striped table-hover" style="font-size:small;">
          <thead>
            <tr>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white"
                v-on:click="sortRankingPoint('player_name')">{{ $t('headName') }}</th>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white"
                v-on:click="sortRankingPoint('player_level')">{{ $t('headLevel') }}</th>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white"
                v-on:click="sortRankingPoint('player_class')">{{ $t('headClass') }}</th>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white;cursor: pointer;"
                v-on:click="sortRankingPoint('win_count')">
                <img style="width: 25px;height: 25px;" :src="require('@/assets/images/elo-rank/filter.png')" />
                {{ $t('titaleWins') }}
              </th>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white;cursor: pointer;"
                v-on:click="sortRankingPoint('lose_count')">
                <img style="width: 25px;height: 25px;" :src="require('@/assets/images/elo-rank/filter.png')" />
                {{ $t('titaleLose') }}
              </th>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white;cursor: pointer;"
                v-on:click="sortRankingPoint('no_complete')">
                <img style="width: 25px;height: 25px;" :src="require('@/assets/images/elo-rank/filter.png')" />
                {{ $t('titleNoComplete') }}
              </th>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white;cursor: pointer;"
                v-on:click="sortRankingPoint('cure_count')">
                <img style="width: 25px;height: 25px;" :src="require('@/assets/images/elo-rank/filter.png')" />
                {{ $t('titleCure') }}
              </th>
              <!-- Columna de muertes corregida -->
              <th scope="col" style="text-align: center;background-color: dimgray;color:white;cursor: pointer;"
                v-on:click="sortRankingPoint('dies_count')">
                <img style="width: 15px;height: 25px;" :src="require('@/assets/images/elo-rank/filter.png')" />
                {{ $t('titleDiesCount') }}
              </th>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white;cursor: pointer;"
                v-on:click="sortRankingPoint('kills_count')">
                <img style="width: 25px;height: 25px;" :src="require('@/assets/images/elo-rank/filter.png')" />
                {{ $t('titleKills') }}
              </th>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white">
                {{ $t('headRank') }}
              </th>
              <th scope="col" style="text-align: center;background-color: dimgray;color:white;cursor: pointer;"
                v-on:click="sortRankingPoint('ranking_point_pvp')">
                <img style="width: 25px;height: 25px;" :src="require('@/assets/images/elo-rank/filter.png')" />
                {{ $t('headPoint') }}
              </th>
              <!-- Nueva columna para warnings -->
              <th scope="col" style="text-align: center;background-color: dimgray;color:white;cursor: pointer;"
                v-on:click="sortRankingPoint('warnings')">
                <img style="width: 25px;height: 25px;" :src="require('@/assets/images/elo-rank/filter.png')" />
                {{ $t('headWarnings') }}
              </th>
              <!-- Nueva columna para sanctions -->
              <th scope="col" style="text-align: center;background-color: dimgray;color:white;cursor: pointer;"
                v-on:click="sortRankingPoint('sanctions')">
                <img style="width: 25px;height: 25px;" :src="require('@/assets/images/elo-rank/filter.png')" />
                {{ $t('headSanctions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td style="vertical-align: middle;text-align: center;">{{ item.player_name }}</td>
              <td style="vertical-align: middle;text-align: center;">{{ item.player_level }}</td>
              <td style="vertical-align: middle;text-align: center;">{{ asignar_classFigther(item.player_class) }}</td>
              <td style="vertical-align: middle;text-align: center;">{{ item.win_count }}</td>
              <td style="vertical-align: middle;text-align: center;">{{ item.lose_count }}</td>
              <td style="vertical-align: middle;text-align: center;">{{ item.no_complete }}</td>
              <td style="vertical-align: middle;text-align: center;">{{ item.cure_count }}</td>
              <td style="vertical-align: middle;text-align: center;">{{ item.dies_count }}</td>
              <td style="vertical-align: middle;text-align: center;">{{ item.kills_count }}</td>
              <td style="vertical-align: middle;text-align: center;">
                <img style="height: 40px;width: 40px;" :src="asignar_eloRankImg(item.ranking)" /><br>
                {{ asignar_eloRank(item.ranking) }}
              </td>
              <td style="vertical-align: middle;text-align: center;">{{ item.ranking_point_pvp }}</td>
              <td style="vertical-align: middle; text-align: center;">
                <span :style="item.warnings === 0
                  ? 'color:green'
                  : item.warnings > 3
                    ? 'color:darkred'
                    : item.warnings > 1
                      ? 'color:#ffc107'
                      : 'color:darkred'">
                  {{ item.warnings }}
                </span>
              </td>
              <td style="vertical-align: middle; text-align: center;">
                <span :style="item.sanctions === 0
                  ? 'color:green'
                  : item.sanctions > 3
                    ? 'color:darkred'
                    : item.sanctions > 1
                      ? 'color:#ffc107'
                      : 'color:darkred'">
                  {{ item.sanctions }}
                </span>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <Spinner class="mt-8" :isLoading="validRequestDisable"></Spinner>
      <!-- <nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" href="/" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li class="page-item"><a class="page-link" href="/">1</a></li>
    <li class="page-item"><a class="page-link" href="/">2</a></li>
    <li class="page-item"><a class="page-link" href="/">3</a></li>
    <li class="page-item">
      <a class="page-link" href="/" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>-->
    </div>
  </div>


</template>

<script>
// @ is an alias to /src
import BannerTitle from '@/components/banner-title/BannerTitle'
import { ref } from "vue";
import service from '@/mixins/service.js';
import Spinner from '@/components/spinner/Spinner'
import debounce from 'lodash.debounce'
//import Spinner from '@/components/spinner/Spinner'
export default {
  name: 'EloListView',
  mixins: [service],
  components: {
    BannerTitle,
    Spinner
  },
  methods: {
    asignar_classFigther(player_class) {
      if (player_class === 3) return this.$t('titleBerserker');
      if (player_class === 4) return this.$t('titlePaladin');
      if (player_class === 7) return this.$t('titleRanger');
      if (player_class === 8) return this.$t('titleAsesino');
      if (player_class === 11) return this.$t('titleClerigo');
      if (player_class === 12) return this.$t('titleSabio');
      if (player_class === 15) return this.$t('titleMagoBrujo');
      if (player_class === 16) return this.$t('titleNigromante');
      if (player_class === 27) return this.$t('titleArtificiero');
      if (player_class === 28) return this.$t('titleArtillero');
      if (player_class === 54) return this.$t('titleDuelista');
      if (player_class === 55) return this.$t('titleRelojero');
      if (player_class === 17) return this.$t('titleDW');
      if (player_class === 18) return this.$t('titleHk');
      if (player_class === 19) return this.$t('titleTh');
      if (player_class === 20) return this.$t('titleAo');
      if (player_class === 21) return this.$t('titleProfeta');
      if (player_class === 22) return this.$t('titleMistico');
      if (player_class === 23) return this.$t('titleAM');
      if (player_class === 24) return this.$t('titleDM');
      if (player_class === 29) return this.$t('titlePrime');
      if (player_class === 30) return this.$t('titleOptimus');
      if (player_class === 56) return this.$t('titleSamurai');
      if (player_class === 57) return this.$t('titleMaestroTiempo');
      if (player_class === 32) return this.$t('titleDK');
      if (player_class === 33) return this.$t('titleCaballeroSagrado');
      if (player_class === 34) return this.$t('titleBallestero');
      if (player_class === 35) return this.$t('titleNinja');
      if (player_class === 36) return this.$t('titleSanto');
      if (player_class === 37) return this.$t('titleChaman');
      if (player_class === 38) return this.$t('titleAvatar');
      if (player_class === 39) return this.$t('titleAmoAlmas');
      if (player_class === 40) return this.$t('titleDestructor');
      if (player_class === 41) return this.$t('titleCruzado');
      if (player_class === 42) return this.$t('titleDepredador');
      if (player_class === 43) return this.$t('titleShinobi');
      if (player_class === 44) return this.$t('titleArcangel');
      if (player_class === 45) return this.$t('titleDruida');
      if (player_class === 46) return this.$t('titleBrujo');
      if (player_class === 47) return this.$t('titleShinigami');
      if (player_class === 48) return this.$t('titleMegatron');
      if (player_class === 49) return this.$t('titleGalvatron');
      if (player_class === 50) return this.$t('titleOmega');
      if (player_class === 51) return this.$t('titleDomadorCelestial');
      if (player_class === 58) return this.$t('titleDimensionalista');
      if (player_class === 59) return this.$t('titleOraculo');
      if (player_class === 60) return this.$t('titleEspectroMortal');
      if (player_class === 61) return this.$t('titleAlteradorCosmico');

    },
    asignar_eloRank(ranking) {
      if (ranking === 1) return this.$t('titleHierro');
      if (ranking === 2) return this.$t('titleBronce');
      if (ranking === 3) return this.$t('titlePlata');
      if (ranking === 4) return this.$t('titleOro');
      if (ranking === 5) return this.$t('titlePlatino');
      if (ranking === 6) return this.$t('titleEsmeralda');
      if (ranking === 7) return this.$t('titleDiamante');
      if (ranking === 8) return this.$t('titleMaestro');
      if (ranking === 9) return this.$t('titleGranMaestro');
      if (ranking === 10) return this.$t('titleRetador');
    }
  },
  setup() {
    let validRequestDisable = ref(false)
    let items = ref([])
    let rows = ref()
    let selectedOption = ref('')
    let selectedOptionNivel = ref('')
    let textSearch = ref('')

    const sortRankingPoint = (entity) => {
      switch (entity) {
        case 'player_name':
          items.value.sort((a, b) => a.player_name.localeCompare(b.player_name))
          break;
        case 'player_level':
          items.value.sort((a, b) => b.player_level - a.player_level)
          break;
        case 'player_class':
          items.value.sort((a, b) => a.player_class - b.player_class)
          break;
        case 'win_count':
          items.value.sort((a, b) => b.win_count - a.win_count)
          break;
        case 'lose_count':
          items.value.sort((a, b) => b.lose_count - a.lose_count)
          break;
        case 'no_complete':
          items.value.sort((a, b) => b.no_complete - a.no_complete)
          break;
        case 'cure_count':
          items.value.sort((a, b) => b.cure_count - a.cure_count)
          break;
        case 'dies_count':
          items.value.sort((a, b) => b.dies_count - a.dies_count)
          break;
        case 'kills_count':
          items.value.sort((a, b) => b.kills_count - a.kills_count)
          break;
        case 'warnings':
          items.value.sort(compareWarnings)
          break;
        case 'sanctions':
          items.value.sort(compareSanctions)
          break;
        case 'ranking_point_pvp':
          items.value.sort((a, b) => b.ranking_point_pvp - a.ranking_point_pvp)
          break;
        default:
          break;
      }
    }

    function compareWarnings(a, b) {
      return b.warnings - a.warnings;
    }
    function compareSanctions(a, b) {
      return b.sanctions - a.sanctions;
    }

    const filterlevelrank = async (optionalSync = true) => {
      selectedOption.value = ''
      if (optionalSync) {
        await getTierRankedList()
      }
      if (selectedOptionNivel.value !== '') {
        if (selectedOptionNivel.value === '100') {
          items.value = items.value.filter(item =>
            [91, 92, 93, 94, 95, 96, 97, 98, 99, 100].includes(item.player_level)
          )
        } else {
          const selectedLevel = parseInt(selectedOptionNivel.value);
          items.value = items.value.filter(item => item.player_level === selectedLevel)
        }
      }
    }

    const getTierRankedListClass = async () => {
      validRequestDisable.value = true
      const data = {
        path: selectedOption.value ? `/ranking-pvp/filter/${selectedOption.value}` : '/ranking-pvp/',
        method: 'GET',
      }
      try {
        const tierList = await service.methods.callService(data)
        validRequestDisable.value = false
        items.value = tierList
        rows.value = tierList.length
        if (selectedOption.value === '') {
          filterlevelrank(false)
        }
      } catch (error) {
        console.log("Error al cargar la tier List", error)
        validRequestDisable.value = false
      }
    }

    const getTierListbyName = debounce(async () => {
      validRequestDisable.value = true
      const data = {
        path: textSearch.value ? `/ranking-pvp/filterWord/${textSearch.value}` : '/ranking-pvp/',
        method: 'GET',
      }
      try {
        const tierList = await service.methods.callService(data)
        validRequestDisable.value = false
        items.value = tierList
        rows.value = tierList.length
      } catch (error) {
        validRequestDisable.value = false
      }
    }, 400)

    const getTierRankedList = async () => {
      validRequestDisable.value = true
      const data = {
        path: `/ranking-pvp`,
        method: 'GET',
      }
      try {
        const tierList = await service.methods.callService(data)
        validRequestDisable.value = false
        items.value = tierList
        rows.value = tierList.length
      } catch (error) {
        validRequestDisable.value = false
      }
    }

    let num_results = ref(3)
    let pag = ref(1)

    getTierRankedList()

    return {
      num_results,
      pag,
      rows,
      items,
      sortRankingPoint,
      asignar_eloRankImg: (ranking) => require(`@/assets/images/elo-rank/elo${ranking}.webp`),
      getTierRankedListClass,
      selectedOption,
      filterlevelrank,
      selectedOptionNivel,
      getTierListbyName,
      getTierRankedList,
      textSearch,
      validRequestDisable
    }
  },
}
</script>
<style>
.table {
  width: 100%;
  /* Ensure the table fills the container */
  border-collapse: separate;
  border: black 5px solid;
  border-spacing: 3px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 5px;
}

.main-row {
  margin: 1em;
}

.table thead {
  border-radius: 10px;
  position: sticky;
  /* Use sticky positioning for fixed header */
  top: 0;
  /* Fix the header to the top of the container */
  background-color: white;
  /* Optional: Set background color for header */
  z-index: 1;
  /* Ensure header stays above content when scrolling */
}

.table thead th {
  padding: 10px;
  /* Adjust padding as needed */
}

.refresh-button {
  cursor: pointer;
}
</style>
