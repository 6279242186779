<template>
  <!-- <div class="container-card-award header-title-text">
    <span class="flex justify-content-center pb-2">{{ msg }}</span>
    <div class="card-scale shadow-lg container containerAw"
      :class="!scaleVal || claimed ? ' card-grey' : 'cursor-pointer'">
      <img :src="image ? image : scaleImagen" class="image-card-scale" />
      <div v-if="claimed" class="centered">{{ $t('claimed') }}</div>
    </div>
  </div>  -->
  <div class="container-card-award header-title-text">
    <span class="flex justify-content-center pb-2">{{ msg }}</span>
    <div class="card-aw-new">

      <div class="content">

        <div class="back">
          <div class="back-content">
            <!-- -->
            <div class="card-scale container containerAw"
              :class="!scaleVal || claimed ? ' card-grey' : 'cursor-pointer'">
              <img :src="image" class="image-card-scale" />
              <div v-if="items && items.length > 0" class="item-grid" :class="`items-${Math.min(items.length, 6)}`">
                <img v-for="(item, index) in items.slice(0, 6)" :key="index"
                  :src="`https://gfmemories.com/images/itemicon/${item.ui_id_1?.toUpperCase()}.png`"
                  class="item-icon" />
              </div>

              <div v-if="claimed" class="centered">{{ $t('claimed') }}</div>
            </div>
          </div>
        </div>
        <div class="front-card-award">
          <div class="img">
            <div class="circle">
            </div>
            <div class="circle" id="right">
            </div>
            <div class="circle" id="bottom">
            </div>
          </div>

          <div class="front-content">
            <small class="badgeAward">Escala {{ number }}</small>
            <div class="description">
              <div class="title" style="color:white">
                <p class="title">
                  <strong><span style="color:white;font-size: 12px;"> {{ nameItem }} <br v-if="quantity > 1"> {{
                    quantity
                      > 1 ? `x ${quantity}` : '' }}</span></strong>
                </p>
                <img width="15px" height="15px" src="@/assets/images/logo_blanco.png" />
              </div>
              <p style="color:white;font-size: 10px;" class="card-footer">
                {{ description || cleanHTML(descriptionItem) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>

<script>
export default {
  name: 'card-award-main',
  props: {
    msg: String,
    nameItem: String,
    descriptionItem: String,
    description: String,
    image: String,
    src: String,
    scaleVal: Boolean,
    claimed: Boolean,
    number: Boolean,
    items: Array,
    quantity: Number,
  },
  setup() {
    const cleanHTML = (input) => {
      if (!input) return '';

      // 1. Eliminar etiquetas HTML
      let cleaned = input.replace(/<\/?[^>]+(>|$)/g, '');

      // 2. Eliminar comillas simples o dobles
      cleaned = cleaned.replace(/['"]/g, '').trim();

      // 3. Insertar espacio si falta luego de un punto (para legibilidad)
      cleaned = cleaned.replace(/\.([A-Z])/g, '. $1');

      // 4. Buscar el primer punto seguido de letra o espacio (no número)
      const match = cleaned.match(/\.(?=\s*[a-zA-Z])/);
      if (match) {
        const cutIndex = match.index;
        return cleaned.slice(0, cutIndex + 1);
      }

      return cleaned;
    };


    return {
      cleanHTML
    };
  },
};
</script>
<style scoped>
.item-grid {
  position: absolute;
  top: 50%;
  left: 50%;
  display: grid;
  gap: 2px;
  transform: translate(-50%, -50%);
  z-index: 222;
}

/* Layouts adaptativos */
.items-1 {
  grid-template-columns: repeat(1, 1fr);
}

.items-2,
.items-3 {
  grid-template-columns: repeat(2, 1fr);
}

.items-4 {
  grid-template-columns: repeat(2, 1fr);
}

.items-5,
.items-6 {
  grid-template-columns: repeat(3, 1fr);
}

/* Íconos individuales */
.item-icon {
  width: 40px;
  height: 40px;
  border: 1px dashed black;
  border-radius: 2px;
  background-color: rgb(243, 208, 180);
}
</style>

<style>
.containerAw {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 100%;
  font-size: 19px;
  z-index: 333;
  background-color: rgba(255, 0, 0, 0.5);
}

.button-custom-gf {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}

.container-card-award {
  margin-top: 1em;
}

.card-scale {
  padding: 0px;
  width: 100px;
  height: 180px;
  background: linear-gradient(135deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);
}

.image-card-scale {
  width: 190px;
  height: 254px;
}

.header-title-text span {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #ffffff;
}

.card-grey {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.card-aw-new {
  overflow: visible;
  width: 190px;
  height: 254px;
  padding-left: 0px;
  padding-right: 0px;
}

.content {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 300ms;
  box-shadow: 0px 0px 10px 1px #000000ee;
  border-radius: 5px;
}

.front-card-award,
.back {
  background-color: #151515;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  overflow: hidden;
}

.back {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.back::before {
  position: absolute;
  content: ' ';
  display: block;
  width: 160px;
  height: 160%;
  background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
  animation: rotation_481 5000ms infinite linear;
}

.back-content {
  position: absolute;
  width: 99%;
  height: 99%;
  background-color: #151515;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.card-aw-new:hover .content {
  transform: rotateY(180deg);
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.front-card-award {
  transform: rotateY(180deg);
  color: white;
}

.front-card-award .front-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.front-content .badgeAward {
  background-color: #00000055;
  padding: 2px 10px;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  width: fit-content;
}

.description {
  box-shadow: 0px 0px 10px 5px #00000088;
  width: 100%;
  padding: 10px;
  background-color: #00000099;
  backdrop-filter: blur(5px);
  border-radius: 5px;
}

.title {
  font-size: 11px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.title p {
  width: 50%;
}

.card-footer {
  color: #fffffff8;
  margin-top: 5px;
  font-size: 10px;
}

.front-card-award .img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ffbb66;
  position: relative;
  filter: blur(15px);
  animation: floating 2600ms infinite linear;
}

#bottom {
  background-color: #ff8866;
  left: 50px;
  top: 0px;
  width: 150px;
  height: 150px;
  animation-delay: -800ms;
}

#right {
  background-color: #ff2233;
  left: 160px;
  top: -80px;
  width: 30px;
  height: 30px;
  animation-delay: -1800ms;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}
</style>