<template>
  <div class="container-card-award header-title-text">
    <div class="progress border border-white" id="progress-scale-award" role="progressbar" aria-label="Basic example"
      aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
      <div id="progres-bar-scale-run" class="progress-bar" :style="style">
        <div class="font-medium" style="width: 100%">{{ progressAP }} MP</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: 'progress-bar-main',
  props: {
    msg: String,
    image: String,
    progressAP: Number,
    limit: Number,
  },
  setup(props) {
  const style = computed(() => {
    const percentage = (Number(props.progressAP) * 100) / (Number(props.limit) || 50000);
    const bounded = Math.min(100, Math.max(0, percentage));
    return `width: ${bounded}%`;
  });

  return {
    style
  };
}

};
</script>

<style>
.button-custom-gf {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}

.container-card-award {
  width: 196px;
}

.card-scale {
  width: 100%;
  height: 246px;
}

.image-card-scale {
  width: 100%;
  height: 100%;
}

.header-title-text span {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #ffffff;
}


#progress-scale-award.progress {
  background: transparent;
}

#progres-bar-scale-run.progress-bar {
  background: #e9952e
}</style>