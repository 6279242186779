<template>
  <div class="dark"
    style="min-height: min-content;width: 400px;max-width:max-content;max-height: fit-content;font-size: xx-small !important;">
    <div class="item-information-table">
      <tbody>
        <td class="table-tooltip">
          <div :class="`gf-tooltip`">
            <div class="media mb-2 mt-2 pl-2 row">
              <div class="col-8 item-name2 media-body text-left">
                <h6 :class="`quality-${itemDataFinal.item_quality}`">{{ itemDataFinal.item_name }} <span
                    v-if="itemDataFinal.rebirth_count === '1'">{{ $t('reencarnadoText') }}</span></h6>
              </div>
            </div>
            <div class="item-general">
              <div class="ride-combo">
              </div>
            </div>
            <div class="media mb-2 mt-2 pl-4 row item-general">
              <div class="stat" v-if="itemDataFinal.p_atk !== ''">
                ATK <span>+{{ itemDataFinal.p_atk }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.r_atk !== ''">
                R-ATK <span>+{{ itemDataFinal.r_atk }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.p_def !== ''">
                DEF <span>+{{ itemDataFinal.p_def }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.m_def !== ''">
                M-DEF <span>+{{ itemDataFinal.m_def }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.atk_spd !== ''">
                SPD
                <span>+{{ itemDataFinal.atk_spd }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.str_bonus !== ''">
                STR
                <span>+{{ itemDataFinal.str_bonus }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.vit_bonus !== ''">
                VIT
                <span>+{{ itemDataFinal.vit_bonus }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.int_bonus !== ''">
                INT
                <span>+{{ itemDataFinal.int_bonus }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.agi_bonus !== ''">
                AGI
                <span>+{{ itemDataFinal.agi_bonus }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.wil_bonus !== ''">
                WILL
                <span>+{{ itemDataFinal.wil_bonus }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.bonus_hp !== ''">
                <span class="color-59">{{ `+${itemDataFinal.bonus_hp} HP` }} </span>
              </div>
              <div class="stat" v-if="itemDataFinal.bonus_mp !== ''">
                <span class="color-59">+{{ `${itemDataFinal.bonus_mp} MP` }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.accuracy !== ''">
                <span class="color-59">+{{ `${itemDataFinal.accuracy} ${$t('opporunityHitText')}` }} </span>
              </div>
              <div class="stat" v-if="itemDataFinal.eva !== ''">
                <span class="color-59">+{{ `${itemDataFinal.eva} ${$t('opporunityEvaText')}` }}</span>
              </div>
              <div class="item-general" v-if="itemDataFinal.p_critical_dmg !== ''">
                <span class="color-59">
                  +{{ `${itemDataFinal.p_critical_dmg} ${$t('opporunityPhysicCriticalDMGText')}` }}
                </span>
              </div>
              <div class="item-general" v-if="itemDataFinal.m_critical_dmg !== ''">
                <span class="color-59">
                  +{{ `${itemDataFinal.m_critical_dmg} ${$t('opporunityMagicCriticalDMGText')}` }}
                </span>
              </div>
              <div class="item-general" v-if="itemDataFinal.p_critical_rate !== ''">
                <span class="color-59">
                  +{{ `${itemDataFinal.p_critical_rate} ${$t('opporunityPhysicCriticalRateText')}` }}
                </span>
              </div>
              <div class="item-general" v-if="itemDataFinal.m_critical_rate !== ''">
                <span class="color-59">
                  +{{ `${itemDataFinal.m_critical_rate} ${$t('opporunityMagicCriticalRateText')}` }}
                </span>
              </div>
              <div class="stat"
                v-if="itemDataFinal.attribute_dmg_text !== null && itemDataFinal.attribute_dmg_text !== '' && itemDataFinal.attribute_dmg_text">
                <span class="color-59">{{ `${itemDataFinal.attribute_dmg_text}` }}</span>
              </div>
              <div class="stat"
                v-if="itemDataFinal.attribute_resistence_text !== null && itemDataFinal.attribute_resistence_text !== '' && itemDataFinal.attribute_resistence_text">
                <span class="color-59">{{ `${itemDataFinal.attribute_resistence_text}` }}</span>
              </div>
              <div class="stat" v-if="itemDataFinal.durability !== ''">
                {{ $t('durabilityText') }}
                <span>{{ `${itemDataFinal.durability}/${itemDataFinal.durability} (${itemDataFinal.durability})` }}
                </span>
              </div>
            </div>
            <div class="media mb-2 mt-2 pl-4 row">
              <div class="item-general" v-if="itemDataFinal.class_group !== '' && fromScale">
                <span v-for="(id_class, index) in itemDataFinal.class_group" v-bind:key="index">{{
                  asignar_classFigther(parseInt(id_class)) }}<span
                    v-if="index !== itemDataFinal.class_group.length - 1">/</span>
                  <br v-if="index === 2 || index === 5 || index === 8"></span>

              </div>
              <div class="item-description" v-if="itemDataFinal.item_description !== '' && (Array.isArray(itemDataFinal.item_description) ? itemDataFinal.item_description.length > 0 : Object.keys(itemDataFinal.item_description).length > 0)">
                <span v-html="itemDataFinal.item_description"></span>
              </div>
              <div class="secondary-description" v-if="itemDataFinal.secondary_description !== ''">
                <span v-html="itemDataFinal.secondary_description"></span>
              </div>
              <div class="item-general">
              </div>

              <div class="item-general">
                <div id="ride-synthesis" class="ride-synthesis">
                  <span class="" id="stats"></span>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tbody>
    </div>
  </div>

</template>
<script>
import { ref } from "vue";
export default {
  name: 'tootip-item-data-main',
  components: {
  },
  props: {
    itemData: Object,
    fromScale: Boolean,
  },
  methods: {
    asignar_classFigther(player_class) {
      if (player_class === 0) return this.$t('titleNovato');
      if (player_class === 13) return this.$t('titleHechicero');
      if (player_class === 5) return this.$t('titleCazador');
      if (player_class === 52) return this.$t('titleViajero');
      if (player_class === 25) return this.$t('titleMecanico');
      if (player_class === 2) return this.$t('titleLuchador');
      if (player_class === 9) return this.$t('titleAcolito');
      if (player_class === 14) return this.$t('titleMago');
      if (player_class === 6) return this.$t('titleArquero');
      if (player_class === 53) return this.$t('titleNomada');
      if (player_class === 26) return this.$t('titleIngeniero');
      if (player_class === 2) return this.$t('titleGuerrero');
      if (player_class === 10) return this.$t('titleSacerdote');
      if (player_class === 3) return this.$t('titleBerserker');
      if (player_class === 4) return this.$t('titlePaladin');
      if (player_class === 7) return this.$t('titleRanger');
      if (player_class === 8) return this.$t('titleAsesino');
      if (player_class === 11) return this.$t('titleClerigo');
      if (player_class === 12) return this.$t('titleSabio');
      if (player_class === 15) return this.$t('titleMagoBrujo');
      if (player_class === 16) return this.$t('titleNigromante');
      if (player_class === 27) return this.$t('titleArtificiero');
      if (player_class === 28) return this.$t('titleArtillero');
      if (player_class === 54) return this.$t('titleDuelista');
      if (player_class === 55) return this.$t('titleRelojero');
      if (player_class === 17) return this.$t('titleDW');
      if (player_class === 18) return this.$t('titleHk');
      if (player_class === 19) return this.$t('titleTh');
      if (player_class === 20) return this.$t('titleAo');
      if (player_class === 21) return this.$t('titleProfeta');
      if (player_class === 22) return this.$t('titleMistico');
      if (player_class === 23) return this.$t('titleAM');
      if (player_class === 24) return this.$t('titleDM');
      if (player_class === 29) return this.$t('titlePrime');
      if (player_class === 30) return this.$t('titleOptimus');
      if (player_class === 56) return this.$t('titleSamurai');
      if (player_class === 57) return this.$t('titleMaestroTiempo');
      if (player_class === 32) return this.$t('titleDK');
      if (player_class === 33) return this.$t('titleCaballeroSagrado');
      if (player_class === 34) return this.$t('titleBallestero');
      if (player_class === 35) return this.$t('titleNinja');
      if (player_class === 36) return this.$t('titleSanto');
      if (player_class === 37) return this.$t('titleChaman');
      if (player_class === 38) return this.$t('titleAvatar');
      if (player_class === 39) return this.$t('titleAmoAlmas');
      if (player_class === 48) return this.$t('titleMegatron');
      if (player_class === 49) return this.$t('titleGalvatron');
      if (player_class === 58) return this.$t('titleDimensionalista');
      if (player_class === 59) return this.$t('titleOraculo');
      if (player_class === 60) return this.$t('titleEspectroMortal');
      if (player_class === 61) return this.$t('titleAlteradorCosmico');
      if (player_class === 40) return this.$t('titleDestructor');
      if (player_class === 41) return this.$t('titleCruzado');
      if (player_class === 42) return this.$t('titleDepredador');
      if (player_class === 43) return this.$t('titleShinobi');
      if (player_class === 44) return this.$t('titleArcangel');
      if (player_class === 45) return this.$t('titleDruida');
      if (player_class === 46) return this.$t('titleBrujo');
      if (player_class === 47) return this.$t('titleShinigami');
      if (player_class === 50) return this.$t('titleOmega');
      if (player_class === 51) return this.$t('titleDomadorCelestial');
    },

  },
  setup(props) {
    let style = ref('null')
    let lang = ref('')
    let loading = ref(true);
    let validRequestDisable = ref(false)
    lang.value = localStorage.getItem('language')
    let itemDataFinal = ref('')
    if (lang.value === 'br') {
      lang.value = 'pt'
    }

    const getItemById = async (item) => {

      itemDataFinal.value = Object.assign({}, item);
      try {

        validRequestDisable.value = false

        let item_nameJSON = itemDataFinal.value.item_name
        itemDataFinal.value.item_name = await item_nameJSON[lang.value]
        if (item.class_group !== '') {
          let classCombinationArray = await itemDataFinal.value.class_group;
          itemDataFinal.value.class_group = classCombinationArray
        }
        if (itemDataFinal.value.sprite_skill !== '') {
          let sprite_skill_nameJSON = await itemDataFinal.value.sprite_skill_name

          itemDataFinal.value.sprite_skill_name = sprite_skill_nameJSON[lang.value]
        }
        if (itemDataFinal.value.secondary_description !== '') {
          let secondary_descriptionJSON = await itemDataFinal.value.secondary_description
          itemDataFinal.value.secondary_description = secondary_descriptionJSON[lang.value]
        }
        if (itemDataFinal.value.item_description !== '') {
          let item_descriptionJSON = await itemDataFinal.value.item_description
          itemDataFinal.value.item_description = item_descriptionJSON[lang.value]
        }
        if (itemDataFinal.value.rebirth_count !== '') {
          let item_drebirthRestriccionJSON = await itemDataFinal.value.rebirth_restriction_text
          itemDataFinal.value.rebirth_restriction_text = item_drebirthRestriccionJSON[lang.value]
        }
        if (itemDataFinal.value.restrictions && itemDataFinal.value.restrictions !== null && itemDataFinal.value.restrictions !== '') {
          let RestriccionJSON = await itemDataFinal.value.restrictions
          itemDataFinal.value.restrictions = RestriccionJSON[lang.value]
        }
        if (itemDataFinal.value.unique_slot !== null && itemDataFinal.value.unique_slot !== '' && itemDataFinal.value.unique_slot !== '0' && itemDataFinal.value.unique_slot) {
          let restriccionUniqueJSON = await itemDataFinal.value.unique_slot
          itemDataFinal.value.unique_slot = restriccionUniqueJSON[lang.value]
        }
        if (itemDataFinal.value.attribute_dmg_text && itemDataFinal.value.attribute_dmg_text !== '' && itemDataFinal.value.attribute_dmg_text !== null) {
          let atributteJSON = await itemDataFinal.value.attribute_dmg_text
          itemDataFinal.value.attribute_dmg_text = atributteJSON[lang.value]
        }
        if (itemDataFinal.value.attribute_resistence_text && itemDataFinal.value.attribute_resistence_text !== '' && itemDataFinal.value.attribute_resistence_text !== null) {
          let atributteJSON = await itemDataFinal.value.attribute_resistence_text
          itemDataFinal.value.attribute_resistence_text = atributteJSON[lang.value]
        }
      } catch (error) {
        //console.log("Ha ocurrido un fallo al cargar del item", error)
        validRequestDisable.value = false
      } finally {
        loading.value = false;
      }
    }

    /*const { floatingStyles, middlewareData ,placement} = useFloating(
      reference,
      floating,
      {
        whileElementsMounted: autoUpdate,
        placement: 'top',
        middleware: [shift({
          crossAxis: true,
        }), offset({
          crossAxis: 20,
        }), flip(), arrow({ element: floatingArrow }),hide()],
      },
    );*/
    //style = `background : linear-gradient(90deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`
    style = `background : linear-gradient(to right, #212121, #141414);boder: 3px solid #212121`
    getItemById(props.itemData)
    return {
      style,
      lang,
      loading,
      getItemById,
      itemDataFinal
    };
  },
};


</script>

<style scoped>
.table tbody td.table-tooltip {
  padding: 0;
  vertical-align: middle;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.row>* {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.item-name2 {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

.crafting-table {
  padding: 0em 2em !important;
}

.crafting-table.enchants span {
  font-weight: lighter;
  font-size: 12px;
}

.content-in {
  font-size: 12px;
}

.media {
  display: flex;
  align-items: flex-start;
}

.item-information-table .item-description span,
.item-information-table .secondary-description span {
  text-align: justify;
  display: block;
  padding-right: 2em !important;
}

.table tr {
  cursor: pointer;
}

.table th,
.table td {
  vertical-align: middle;
  background-color: #464646;
  color: #FFFFFF;
}

a {
  color: #FFFFFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.dark {
  padding: 0;
}

.item-general.row div {
  padding-left: 0;
}

.content-in td:hover {
  cursor: pointer;
  text-decoration: underline;
}

.exclusive {
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: inset 0 0 5px yellow;
  }

  50% {
    box-shadow: inset 0 0 10px yellow;
  }

  100% {
    box-shadow: inset 0 0 5px yellow;
  }
}
</style>