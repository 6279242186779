import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisterView from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import TermsView from '../views/Terms.vue'
import Tickets from '../views/Tickets.vue'
import News from '../views/NewsView.vue'
import BuildApp from '../views/BuildApp.vue'
import NewsForm from '../components/news/newsForm.vue'
import ChangeNameView from '../views/ChangeNameView.vue'
import AwardsMPView from '../views/AwardsMPView.vue'
import PlansPayMPView from '../views/PlansPayMPView.vue'
import ReceiptPayments from '../views/ReceiptPayments.vue'
import CodeVerifyView from '../views/CodeVerifyView.vue'
import Profile from '../views/Profile.vue';
import EloListView from '@/views/EloListView.vue'
import ItemView from '@/views/database/ItemView.vue'
import ItemSingle from '@/views/database/ItemSingle.vue'
import ListItemsView from '@/views/database/ListItemsView.vue'
import DatabaseMainView from '@/views/database/DatabaseMainView.vue'
import CancelPayments from '@/views/CancelPayments.vue'
import MonsterView from '@/views/database/MonsterView.vue'
import GuideView from '@/views/database/GuideView.vue'
import DownloadsView from '@/views/DownloadsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: RegisterView,
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LoginView,
  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/tickets',
    name: 'tickets',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Tickets,
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: News,
  },
  {
    path: '/processing',
    name: 'processing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: BuildApp,
  },
  {
    path: '/news/:id',
    name: 'newsForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: NewsForm,
  },
  {
    path: '/terms',
    name: 'terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TermsView,
  },
  {
    path: '/elo-tier',
    name: 'eloTier',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: EloListView,
  },
  {
    path: '/database',
    name: 'itemData',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ItemView,
    redirect: '/database/list-items/0',
    children: [
      {
        path: 'list-items/:item_type/:auction_type?/:rune_type?',
        name: 'listItemsView',
        component: ListItemsView,
      },
      {
        path: 'main',
        name: 'databaseMain',
        component: DatabaseMainView,
      },
      {
        path: 'item/:id_item/:name?/:lang?',
        name: 'itemSingleView',
        component: ItemSingle,
      },
      {
        path: 'monster/:id_monster/:name?/:lang?',
        name: 'monsterView',
        component: MonsterView,
      },
      {
        path: 'guide',
        name: 'guideView',
        component: GuideView,
      }
    ],
  },

  {
    path: '/change-name',
    name: 'change-name',
    // UserProfile will be rendered inside User's <router-view>
    component: ChangeNameView
  },
  {
    path: '/scales',
    name: 'scales',
    component: AwardsMPView,
    meta: { requiresAuth: true }
  },
  {
    path: '/blank',
    name: 'blank',
    component: AwardsMPView,
    meta: { requiresAuth: true }
  },
  {
    path: '/store',
    name: 'store',
    component: PlansPayMPView,
    meta: { requiresAuth: true }
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: DownloadsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/receipt-payment',
    name: 'receipt-payment',
    component: ReceiptPayments,
  },
  {
    path: '/cancel-payment',
    name: 'cancel-payment',
    component: CancelPayments,
  },
  {
    path: '/verify-code',
    name: 'verify-code',
    component: CodeVerifyView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // Verifica si la ruta requiere autenticación
  document.title = `GF Memories`
  if (to.fullPath.includes('/#/')) {
    // Si la ruta tiene un hash, eliminarlo y redirigir a la misma ruta sin hash
    const cleanPath = to.fullPath.replace('/#/', '/');
    next(cleanPath);
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Verifica si el usuario está autenticado (implementa tu lógica de autenticación aquí)
    const validateLogin = localStorage.getItem('validateLogin');
    const accessToken = localStorage.getItem('access_token');
    let isAuthenticated = false;
    if (validateLogin == 'true' && accessToken) {
      isAuthenticated = true/* Verificar la autenticación de tu usuario */
    } else {
      isAuthenticated = false
    }
    if (!isAuthenticated) {
      // Si el usuario no está autenticado, redirige a la página de inicio de sesión u otra página de inicio
      next({ name: 'login' });
    } else {
      // Si el usuario está autenticado, permite el acceso a la ruta
      if (to.name == 'login') {
        next({ name: '/' });
      } else {
        next();
      }

    }
  } else {
    // Si la ruta no requiere autenticación, permite el acceso sin restricciones
    next();
  }
})

export default router
