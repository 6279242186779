<template>
  <BannerTitle :msg="$t('download')"></BannerTitle>
<div class="container mt-5">
  <div class="row">
    <div class="flex w-full justify-content-center">
        <div class="m-5 alert alert-primary col-10 md:col-5 text-center" style="width: 450px !important;" role="alert">
          <span class="fa fa-info-circle"></span> {{ $t('downloadInstructions') }}
          <br>
        </div>
      </div>
      <div class="flex w-full justify-content-center">
        <div class="m-5 alert alert-warning col-10 md:col-5 text-center" style="width: 450px !important;" role="warning">
          <span class="fa fa-info-circle"></span>   <span v-html="$t('downloadInstructionsRar')"></span>
          <br>
        </div>
      </div>
      <hr/>
    <div class="container mt-5 col-md-6 text-center">
      <h2>
        <img src="@/assets/images/drive_icon.png" width="100" height="100" alt="google drive icon" class="mb-2 mx-auto d-block">
        <br>
        Google Drive Links
      </h2>
      <div class="row justify-content-center">
        <div class="col-auto">
          <a :href="urlDownloadGDrive" target="_blank" class="btn btn-primary btn-block">
            {{$t('textDownloadComplete')}}
          </a>
        </div>
        <div class="col-auto">
          <a :href="urlDownloadGDriveGVG" target="_blank" class="btn btn-primary btn-block">
            {{$t('textDownloadGVG')}}
          </a>
        </div>
      </div>
    </div>
    <div class="container mt-5 col-md-6 text-center">
      <h2>
        <img src="@/assets/images/mediafire_icon.png" width="100" height="100" alt="mediafire icon" class="mb-2 mx-auto d-block">
        <br>
        MediaFire Links
      </h2>
      <div class="row justify-content-center">
        <div class="col-auto">
          <a :href="urlDownloadMediafire" target="_blank" class="btn btn-success btn-block">
            {{$t('textDownloadComplete')}}
          </a>
        </div>
        <div class="col-auto">
          <a :href="urlDownloadMediafireGVG" target="_blank" class="btn btn-success btn-block">
            {{$t('textDownloadGVG')}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <hr/>
  <div class="flex w-full justify-content-center">
        <div class="m-5 alert alert-warning col-10 md:col-5 text-center" style="width: 450px !important;" role="warning">
          <span class="fa fa-info-circle"></span>   <span v-html="$t('errorInstructions')"></span>
          <br>
        </div>
      </div>
  <div class="flex w-full justify-content-center">
    <iframe class="border-round" frameborder="0" width="500px" height="300px"
                  src="https://www.youtube.com/embed/h190F-V5XKA">
    </iframe>
  </div>
</div>

</template>

<script>
import BannerTitle from '@/components/banner-title/BannerTitle'
import { ref } from "vue";
export default {
  name: 'PlansPayMPView',
  components: {
    BannerTitle,
  },
  setup() {
    let urlDownloadGDrive = ref(process.env.VUE_APP_URL_DOWNLOAD_GDRIVE);
    let urlDownloadMediafire = ref(process.env.VUE_APP_URL_DOWNLOAD_MEDIAFIRE);
    let urlDownloadGDriveGVG = ref(process.env.VUE_APP_URL_DOWNLOAD_GDRIVE_GVG);
    let urlDownloadMediafireGVG = ref(process.env.VUE_APP_URL_DOWNLOAD_MEDIAFIRE_GVG);
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }
    topPage()
    return {
      urlDownloadGDrive,
      urlDownloadMediafire,
      urlDownloadMediafireGVG,
      urlDownloadGDriveGVG,
    }
  },
}
</script>
<style scoped>
.container h2,
.container a {
  color: snow;
}

hr {
  height: 1px;
  background-color: white;
  border: none;
}
</style>

